.app__logo {
  height: 5rem;
  pointer-events: none;
  margin: 1rem 0;
}

.app__header {
  text-align: center;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  color: white;
  padding: 2rem 1rem;
}

.app__link {
  color: #61dafb;
}

.app__navigation {
  background-color: #004ae0;
  padding: 0.5rem 1rem;
  height: 78px;
}

.app__menu {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
}

.app__menu-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
}

.app__menu-link:focus {
  outline-color: #9ebeff;
}

.app__menu-link:hover {
  color: #9ebeff;
}


